<template>
  <div class="about">
    <main-nav v-if="$vuetify.breakpoint.mdAndUp"></main-nav>

    <mobile-nav v-if="$vuetify.breakpoint.smAndDown"></mobile-nav>
    <div class="container">


      <h1>YouTube + Tutorials = Youtorial! 🎉</h1>
      <p>I LOVE LOVE LOVE watching tutorials on YouTube, especially when it comes to beer brewing, programming code, repairing things at home, or even learning a song or dance. Reading is cool, and there’s some great blogs out there, yet sometimes seeing HOW something is accomplished is more effective.</p>
      <strong>BUT…</strong>
      <br>
      <br>
      <p>I cannot tell you how frustrating it is to watch a tutorial on Youtube and think:</p>
      <ul>
          <li>How many steps go into this?</li>
          <li>Where I am in the process?</li>
          <li>Wait, didn’t they say something important a while back?</li>
      </ul>

      <p>Not to mention I have to start, stop, pause, play, rewind, skip and try to find places that I need to re-watch so I can learn that step.</p>

      <h2>Enter Youtorial!</h2>
      <p>Now you can SEE all the steps in a tutorial, replay or skip those steps, and learn at your own pace by reading and seeing. It’s a great addon to these types of videos, and can help viewers really get into your content.</p>

      <h2>Benefits for YouTube Creators</h2>
      <ul>
        <li>Text + Video means your viewers learn easier.</li>
        <li>Easier learning means more views.</li>
        <li>More views means more revenue.</li>
      </ul>
      <p>Let’s check out how to get there: The create screen.</p>

      <img src="https://miro.medium.com/max/1400/1*62O-6ybZ3EMcGvpVV8LtkA.png" alt="">

      <p>Your video can be broken up into “steps” by adding a breakpoint at a time in the video. These steps can have titles, descriptive text, and optionally pause the video if you think viewers may need time before proceeding.</p>
      <strong>Left-side:</strong>
      <ul>
        <li>Your video and the description you entered from YouTube.</li>
        <li>The “add step” bar to add steps or breakpoints</li>
      </ul>
      <br>
      <strong>Right-side:</strong>
      <ul>
        <li>Publishing options so you can customize content.</li>
        <li>Any steps you added, their content and timestamp.</li>
        <li>You can play from any step, or even delete them if no longer needed.</li>
      </ul>
      <br>

      <h2>Benefits for YouTube Viewers</h2>
      <ul>
        <li>Know what they’re getting into before starting.</li>
        <li>Explore the content the way they need.</li>
        <li>Learn at their own pace, and in some cases use voice commands**</li>
      </ul>
      <i>** some older browsers aren’t compatible with voice commands</i> <br><br>
      <p>Let’s see things from the viewer’s perspective:</p>

      <img src="https://miro.medium.com/max/1400/1*iFywTIFErfa-m7DILoVLHA.png" alt="">
      <p>Wow, soooo clean right? On the left you can see</p>
      <ul>
        <li>The YouTube video tutorial</li>
        <li>Time bar with markers that show locations of all steps</li>
        <li>“Skip Intro” button like Netflix to get right into learning</li>
        <li>Step progress bar with “replay” and “skip” buttons</li>
        <li>Video title and description with Youtube video and channel links</li>
      </ul>
      <br>
      <br>
      <strong>On the right side:</strong>
      <ul>
        <li>Total progress bar and current step indicator</li>
        <li>List of steps as cards that you can read and click to skip around</li>
        <li>Toggle button that can show only the current step for focused learning.</li>
      </ul>
      <br>

      <h2>Let’s get some feedback!</h2>
      <p>Youtorial needs your help to learn how to be better for everyone! Please try it out and leave some feedback in the help menu link.</p>
    </div>
  </div>
</template>

<script>
const mainNav = () => import("@/components/shared/nav.vue");
const mobileNav = () => import("@/components/shared/mobile-nav.vue");
export default {
  name: "about",
  metaInfo: {
    title: 'Youtorial: About'
  },
  components:{
    mainNav,mobileNav,
  },
  data(){
    return{
      videos: [],
      isLoaded: false,
      loading:true,
      userId:0,
    }
  },
  methods:{

  },
  mounted(){

    if(this.$vuetify.breakpoint.mdAndUp){
      this.$store.dispatch('toggleDrawer', true);
    }


  }

};
</script>


<style lang="scss">
.about{
  position: relative;
  height: 100vh;
  padding-top: 70px;
  padding-left: 280px;

  &.mobile{
    padding-top:0;
    padding-left: 0;
    .container{
      padding: 0;
      .row {
        margin: 0;
        > div{
          padding: 0;
        }
      }
    }

  }
  img{
    max-width:600px;
  }
  p{
    max-width: 600px;
  }

}
</style>
